<!--
 * @Author: your name
 * @Date: 2020-12-03 10:41:36
 * @LastEditTime: 2020-12-03 14:50:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\liveBroadcast\components\videoPlayer.vue
-->
<template>
  <div class="liveDialog">
    <div class="diaBg"></div>
    <div class="liveContent">
      <span @click="dialogClose" class="el-icon-close close"></span>
      <video class="liveContent__video" ref="videoPlayer"></video>
    </div>
  </div>
</template>
<script>
import Hls from "hls.js";

export default {
  props: {
    livePlay: {
      type: Boolean,
      default: false,
    },
    source: {
      required: true,
      type: String,
    },
    config: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      player: null,
    };
  },
  computed: {},
  //data中的数据变化
  watch: {},
  methods: {
    getVideo() {
      console.log(this.source);
      if (Hls.isSupported()) {
        this.player = new Hls(this.config || {});
        this.player.loadSource(this.source);
        this.player.attachMedia(this.$refs.videoPlayer);
        this.player.on(Hls.Events.MANIFEST_PARSED, () => {
          this.$refs.videoPlayer.play();
        });
        this.player.on(Hls.Events.MANIFEST_PARSED, () => {
          this.$refs.videoPlayer.play();
        });
        this.player.on(Hls.Events.ERROR, (event, data) => {
          switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
              console.log('播放失败')
              this.$message.error('视频播放失败')
              this.dialogClose()
              break;
            default:
              break;
          }
        });
      }
    },
    dialogClose() {
      if (this.player) {
        this.player.destroy();
        this.player = null
      }
      this.$emit("close");
    },
  },
  //创建完成
  created() {},
  //vm实例被挂载后调用-可以访问DOM元素
  mounted() {
    console.log(22);
    setTimeout(() => {
      this.getVideo();
    }, 500);
  },
  //vm初始化后,data observe / event/watcher事件配置之前
  beforeCreate() {},
  //vm挂载之前
  beforeMount() {},
  //数据更新时调用，虚拟DOM打补丁前
  beforeUpdate() {},
  //虚拟DOM重新渲染后
  updated() {},
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
      this.player = null
    }
  },
  destroyed() {},
  //keep-alive缓存组件激活触发
  activated() {},
};
</script>

<style lang='scss' scoped>
.liveDialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .diaBg {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    opacity: 0.5;
    background: #000000;
    z-index: 1001;
  }
  .liveContent {
    width: 800px;
    height: 500px;
    position: relative;
    // left:50%;
    // top:50%;
    z-index: 1002;
    // margin: -400px 0 0 -300px;
    .close {
      position: absolute;
      top: -24px;
      right: -24px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .liveContent__video{
    width: 100%;
    height: 100%;
  }
}
</style>
